import imageUrlBuilder from '@sanity/image-url';

import {
  ImageResponseEdge,
  ImageURLBuilder,
  SanityImageObject,
  SanityImageSource,
} from '../types';

const clientConfig = require('../../client-config');
const builder = imageUrlBuilder(clientConfig.sanity);

export function imageUrlFor(source: SanityImageSource): ImageURLBuilder {
  return builder.image(source);
}

export function buildImageObj(
  source: SanityImageObject = { asset: {} },
): SanityImageObject {
  const imageObj: SanityImageObject = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function getImage(
  edges: Array<ImageResponseEdge>,
  name: string,
): ImageResponseEdge {
  return edges.find(({ node }) =>
    node.childImageSharp.fluid.originalName.startsWith(name),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapEdgesToNodes(data: Record<any, any>): any[] {
  if (!data.edges) return [];

  return data.edges.map((edge) => edge.node);
}
