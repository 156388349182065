/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import '../assets/fonts/inter.css';

import Header from './Header';
import Footer from './Footer';
import CompactFooter from './CompactFooter';
import { TailwindBreakpointIndicator } from './shared/TailwindBreakpointIndicator';

interface Props {
  isIndexPage?: boolean;
}

const Layout: React.FC<Props> = ({ children, isIndexPage = false }) => {
  const { sanityHomePage } = useStaticQuery(graphql`
    query HomePageQuery {
      sanityHomePage {
        title
        address {
          line1
          line2
        }
        email
        phoneNumber
      }
    }
  `);

  return (
    <Fragment>
      <Header light={!isIndexPage} siteTitle={sanityHomePage.title} />

      <main className="antialiased">{children}</main>

      {isIndexPage ? (
        <Footer title={sanityHomePage.title} />
      ) : (
        <CompactFooter
          address={sanityHomePage.address}
          email={sanityHomePage.email}
          phoneNumber={sanityHomePage.phoneNumber}
          title={sanityHomePage.title}
        />
      )}

      <TailwindBreakpointIndicator />
    </Fragment>
  );
};

export default Layout;
