import { useCallback, useState } from 'react';

interface UseBooleanCallbacks {
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
}

function useBoolean(initialValue = false): [boolean, UseBooleanCallbacks] {
  const [value, setValue] = useState(initialValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((prevState) => !prevState), []);

  return [value, { setTrue, setFalse, toggle }];
}

export default useBoolean;
