import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

import logo from '../assets/logo.svg';
import useServiceList from '../hooks/useServiceList';

interface Props {
  title: string;
}

const companyNavigation = {
  name: 'Firma',
  className: 'w-full sm:w-1/6',
  items: [
    { label: 'Početna', href: '/' },
    { label: 'O nama', href: '/#o-nama' },
    { label: 'Kontakt', href: '/#kontakt' },
  ],
};

const projectsNavigation = {
  name: 'Referenc lista',
  className: 'w-full sm:w-1/6 lg:w-2/6',
  items: [
    { label: 'Visokogradnja', href: '/projekti?filter=visokogradnja' },
    { label: 'Niskogradnja', href: '/projekti?filter=niskogradnja' },
    { label: 'Hidrogradnja', href: '/projekti?filter=hidrogradnja' },
  ],
};

const SubNav = ({ navigation }) => (
  <nav
    className={clsx([navigation.className, 'space-y-5 text-gray-600'])}
    key={navigation.name}
  >
    <div className="text-lg font-medium text-gray-900">{navigation.name}</div>

    {navigation.items.map((item) => (
      <Link
        className="block hover:text-blue-600 hover:underline"
        key={item.href.current || item.href}
        to={item.href.current || item.href}
      >
        {item.label}
      </Link>
    ))}
  </nav>
);

const Footer: React.FC<Props> = ({ title }) => {
  const services = useServiceList();
  const servicesNavigation = {
    name: 'Proizvodi i djelatnosti',
    className: 'w-full sm:w-4/6 lg:w-3/6',
    items: services,
  };

  return (
    <footer className="container mb-28 mt-36">
      <div className="flex flex-wrap-reverse justify-center">
        <div className="mt-20 flex flex-col space-y-5 lg:mt-0 lg:w-3/12 lg:items-start">
          <img alt={`${title} logo`} className="h-24 w-auto" src={logo} />

          <div>
            <p>
              © {new Date().getFullYear()} {title}
            </p>
          </div>
        </div>

        <div className="lg:w-2/12" />

        <div className="flex flex-col space-y-10 sm:flex-row sm:space-y-0 lg:w-7/12 lg:space-x-12">
          <SubNav navigation={companyNavigation} />
          <SubNav navigation={servicesNavigation} />
          <SubNav navigation={projectsNavigation} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
