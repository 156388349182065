import { graphql, useStaticQuery } from 'gatsby';

import { Slug } from '../types';
import { mapEdgesToNodes } from '../utils';

interface Service {
  _id: string;
  label: string;
  href: Slug;
}

export default function useServiceList(): Array<Service> {
  const { services } = useStaticQuery(graphql`
    {
      services: allSanityService(sort: { fields: _createdAt }) {
        edges {
          node {
            _id
            label: title
            href: slug {
              current
            }
          }
        }
      }
    }
  `);

  return mapEdgesToNodes(services);
}
