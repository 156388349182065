import React from 'react';
import { Link } from 'gatsby';

import { Facebook, Instagram } from '../assets/Icons';
import type { Address } from '../types';

interface Props {
  address: Address;
  email: string;
  phoneNumber: string;
  title: string;
}

const CompactFooter: React.FC<Props> = ({
  address,
  email,
  phoneNumber,
  title,
}) => {
  const phoneNumberStripped = phoneNumber.replace(/[\/-\s]/gi, '');

  return (
    <footer className="container mb-10 mt-36 space-y-5 py-10 text-gray-700 sm:space-y-0">
      <section className="flex flex-col items-center justify-between space-y-8 pb-5 leading-loose sm:flex-row sm:space-y-0">
        <div className="flex flex-1 flex-col text-center sm:text-left">
          <a
            className="hover:text-blue-500 hover:underline"
            href={`mailto:${email}`}
            rel="noopener noreferrer"
            target="_blank"
            title="Imejl adresa"
          >
            {email}
          </a>

          <a
            className="hover:text-blue-500 hover:underline"
            href={`tel:${phoneNumberStripped}`}
            rel="noopener noreferrer"
            target="_blank"
            title="Broj telefona"
          >
            {phoneNumber}
          </a>
        </div>

        <div className="flex-1">
          <div className="flex flex-col text-center">
            <p>{address.line1}</p>
            <p>{address.line2}</p>
          </div>
        </div>

        <div className="flex flex-1 justify-center space-x-5 sm:justify-end">
          <a
            href="https://instagram.com/kesogradnja"
            rel="noopener noreferer"
            target="_blank"
            title="Link do Instagram naloga"
          >
            <Instagram className="h-7 w-7 hover:text-blue-500 sm:h-5 sm:w-5" />
          </a>
          <a
            href="https://facebook.com/kesogradnja"
            rel="noopener noreferer"
            target="_blank"
            title="Link do Facebook naloga"
          >
            <Facebook className="h-7 w-7 hover:text-blue-500 sm:h-5 sm:w-5" />
          </a>
        </div>
      </section>

      <hr className="border-blue-50" />

      <section className="flex flex-col justify-between space-y-8 pt-5 text-center sm:flex-row sm:space-y-0">
        <Link className="sm:text-left" to="/">
          {title}
        </Link>

        <nav
          aria-label="compact footer's navigation"
          className="relative flex items-center justify-center"
        >
          <div className="block space-x-8">
            <Link className="hover:text-blue-500" to="/#o-nama">
              O nama
            </Link>
            <Link className="hover:text-blue-500" to="/#djelatnosti">
              Djelatnosti
            </Link>
            <Link className="hover:text-blue-500" to="/projekti/">
              Referenc lista
            </Link>
          </div>
        </nav>
      </section>
    </footer>
  );
};

export default CompactFooter;
