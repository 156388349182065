/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { buildImageObj, imageUrlFor } from '../utils';
import { SanityImageObject } from '../types';

interface Props {
  description?: string;
  image?: SanityImageObject;
  lang?: string;
  meta?: HTMLMetaElement[];
  title?: string;
}

const SEO: React.FC<Props> = ({
  description = '',
  lang = 'sr',
  meta = [],
  title,
  image,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata?.description || '';
  const defaultTitle = site.siteMetadata?.title;
  const siteAuthor = site.siteMetadata?.author || '';
  const metaImage = image?.asset
    ? imageUrlFor(buildImageObj(image)).width(1200).url()
    : '';

  return (
    <Helmet
      defaultTitle={defaultTitle}
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteAuthor,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      title={title}
      titleTemplate={`%s | ${defaultTitle}`}
    />
  );
};

export default SEO;
